<template>
  <div class="container">
    <div id="error">
      <ShowPath :path="path" />
      <p v-if="code || msg">
        <span>
          {{ this.code === "" ? "" : this.code + " - " }} {{ this.msg }}
          <br />
          <router-link class="goback" :to="{ path: '/' }"
            >goback home!!</router-link
          >
        </span>
        <span class="goback"> </span>
      </p>
      <p v-else>
        <span> Erreur inconnue. </span><br />
        <router-link class="goback" :to="{ path: '/' }">
          go back home</router-link
        >
      </p>
    </div>
  </div>
</template>
<script>
import ShowPath from "@/components/ShowPath.vue";
export default {
  components: {
    ShowPath,
  },
  props: {
    code: { type: String, required: false },
    msg: { type: String, required: false },
    path: { type: String, required: true },
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.goback {
  color: red;
  text-decoration: none;

  cursor: pointer;
}
.goback:hover {
  text-decoration: underline;
  transform: scale(1.1);
}
#error {
  text-align: center;
}
</style>
