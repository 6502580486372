<template>
  <div class="path--container">
    <div v-if="!isRoot" @click="$router.back()" class="path">
      <span title="go back" class="path--back"
        >&lt;==&nbsp;{{ this.path }}</span
      >
    </div>
    <div v-else class="path">
      <span class="path--back">/</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "ShowPath",
  props: {
    path: { type: String, required: true },
  },
  data() {
    return {
      isRoot: { type: Boolean, default: true },
    };
  },
  created() {
    this.path === "/" ? (this.isRoot = true) : (this.isRoot = false);
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.path--container {
  margin-top: 5px;
  margin-bottom: 5px;
  max-width: 100%;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  cursor: pointer;
  z-index: 1;
}
.path {
  background-color: var(--main-front-color);
  border: 6px outset var(--main-front-color);
}
.path--back {
  background-color: var(--main-front-color);
  font-weight: bold;
}
</style>
